<!--
    Created by 程雨喵'mac on 2023/12/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：登录页-忘记密码
-->
<style lang="less">
@import '../css/loginCommon';
.login-forget-form {
  padding: 40px 40px 30px;
  width: 340px;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.5);
  border-radius: @loginFormHeight * 0.5;
  backdrop-filter: blur(15px);
  box-shadow: 0 0 20px 8px rgba(84,110,253,0.1);
  border: 2px solid white;
  &:hover, &:focus-within {
    border-color: @loginThemeColor;
  }
  h1 {
    margin-bottom: 28px;
    font-size: 19px;
    color: @loginThemeColor;
    text-align: center;
    line-height: 21px;
    text-shadow: 2px 2px 4px rgba(84,110,253, 0.3);
  }
  .form-item {
    .display-flex();
    &+.form-item {
      margin-top: 20px;
    }
    .form-inner {
      flex: 1;
      .display-flex();
      height: @loginFormHeight;
      background-color: white;
      border-radius: @loginBorderRadius;
      overflow: hidden;
      border: 1px solid @loginBorderColor;
      &:hover, &:focus-within {
        border-color: @loginThemeColor;
      }
      .item-left {
        .display-flex();
        .flex-grow(40px);
        height: 16px;
        border-right: 1px solid @loginBorderColor;
        img {
          width: 14px;
        }
      }
      .item-right {
        .display-flex();
        justify-content: flex-start;
        flex: 1;
        min-width: 100px;
        height: 100%;
        font-size: 13px;
        input {
          padding: 0 10px 0 15px;
          flex: 1;
          min-width: 100px;
          height: @loginFormHeight;
          border: 0 !important;
          outline: none !important;
        }
      }
    }
    .form-code {
      width: 100px;
      margin-left: 10px;
      .display-flex();
      height: @loginFormHeight;
      border-radius: @loginBorderRadius;
      overflow: hidden;
      background: white;
      border: 1px solid @loginBorderColor;
      cursor: pointer;
      color: @textColorLight;
      &:hover {
        border-color: @loginThemeColor;
      }
    }
  }
  .other-btn {
    margin-top: @containerGap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 60px;
    color: @textColorLight;
    font-size: 14px;
    .ivu-checkbox-checked {
      .ivu-checkbox-inner {
        border-color: @loginThemeColor;
        background-color: @loginThemeColor;
      }
    }
    .forget {
      cursor: pointer;
    }
  }
  .login-btn {
    margin: 20px 0 10px;
    line-height: @loginFormHeight;
    border-radius: @borderRadiusMin;
    background-color: @loginThemeColor;
    color: white;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
  }
  .go-back-btn {
    margin-top: 8px;
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="login-forget-form">
    <h1>智能充电管理平台重置密码</h1>
    <div
      v-for="(item, i) in formItems"
      :key="i"
      class="form-item"
    >
      <div class="form-inner">
        <div class="item-left">
          <img :src="`/loginImg/${item.icon}.png`">
        </div>
        <div class="item-right">
          <input
            v-model="form[item.key]"
            :type="item.type"
            :placeholder="item.title"
            :maxlength="item.maxLength"
          >
        </div>
      </div>
      <div
        v-if="item.key === 'telCode'"
        class="form-code"
      >
        <yg-otp-button
          style="height: 40px"
          :disabled="!form.account"
          disabled-text="请输入登录账号"
          :username="form.account"
          :is-un-auth="true"
          @on-send="onSendMsgSuccess"
        />
      </div>
    </div>
    <div class="other-btn">
      <Checkbox
        v-model="quickLogin"
      >
        修改并登录
      </Checkbox>
      <span
        class="forget display-flex display-flex-v-center"
        @click.stop="_clickBack"
      >
        返回登录
        <Icon type="ios-arrow-forward" />
      </span>
    </div>
    <div
      class="login-btn"
      @click.stop="_clickSubmit"
    >
      确定
    </div>
  </div>
</template>

<script>
import { isAlphanumeric } from '@/libs/regularUtils';
import CommonApiSet from '@/request/api/CommonApiSet';
import md5 from 'md5';

export default {
  components: {},
  data () {
    return {
      form: {
        account: '',
        password: '',
        againPassword: '',
        telCode: ''
      },
      formItems: [
        { type: 'text', key: 'account', title: '账号', icon: 'login_user', maxLength: 30 },
        { type: 'password', key: 'password', title: '密码', icon: 'login_pwd', maxLength: 50 },
        { type: 'password', key: 'againPassword', title: '确认密码', icon: 'login_pwd', maxLength: 50 },
        { type: 'text', key: 'telCode', title: '手机验证码', icon: 'login_code', maxLength: 6 }
      ],
      quickLogin: false,
      loading: false,
      version: ''
    };
  },
  mounted () {
    this.version = this.$config.version;
  },
  methods: {
    // 发送验证码成功
    onSendMsgSuccess () {
      this.$Modal.success({
        title: '温馨提示',
        content: '验证码将发送到账号绑定的手机号',
        okText: '我知道了'
      });
    },
    _clickSubmit () {
      const form = this.form;
      const emptyItem = this.formItems.find(v => !form[v.key]);
      if (emptyItem) {
        return this.$Message.info(emptyItem.title + '不能为空');
      }
      if (isAlphanumeric(form.password)) {
        if (form.password === form.againPassword) {
          // this.$emit('on-finish', this.form);
          this.requestForget();
        } else {
          this.$Message.info('两次输入的密码不同，请重新输入');
        }
      } else {
        this.$Message.info('输入6~8位密码，必须为数字字母组合');
      }
    },
    _clickForget () {
      this.$Message.info('如需重置密码，请联系直属领导。');
    },
    _clickBack () {
      this.$emit('on-finish');
    },
    requestForget () {
      const api = CommonApiSet.passwordModify;
      api.params = {
        username: this.form.account,
        password: md5(this.form.password),
        captcha: this.form.telCode
      };
      this.$http(api).then(res => {
        this.form = {
          account: '',
          password: '',
          againPassword: '',
          telCode: ''
        };
        // 修改成功
        if (this.quickLogin) {
          // 修改成功后直接登录
          this.$emit('on-finish', this.form);
        } else {
          // 去登录
          this.$Modal.confirm({
            title: '重置成功',
            content: '是否立即去登录？',
            okText: '立即登录',
            onOk: () => {
              this.$emit('on-finish');
            }
          });
        }
      });
    }
  }
};
</script>
