<!--
   登录界面

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
@import './css/loginCommon';
@media screen and (max-width: 1000px) {
  .login-container {
    .login-left-bg {
      position: absolute !important;
      left: 0 !important;
      img.logo {
        left: 50vw !important;
        transform: translateX(-50%) !important;
      }
      .left-bg-outer {
        display: none !important;
      }
    }
    .form-outer {
      padding-left: 0 !important;
      justify-content: center !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }
}
#login {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: transparent url("/loginImg/login_bg1.png") no-repeat 50% 50%;
  background-size: cover;
  overflow: hidden;
  div, p, h1, span, img {
    user-select: none;
  }
  .login-container {
    display: flex;
    .position(absolute, 0, 0, 0, 0);
    overflow: hidden;
    .login-left-bg {
      position: relative;
      left: -100px;
      .flex-grow(54%);
      height: 100vh;
      .display-flex();
      justify-content: flex-start;
      @loginLeftAnimationDuration: 4s;
      @keyframes leftImgShowAnim {
        0% { opacity: 0; transform: scale(3)}
        30% { opacity: 0.3; transform: scale(1) }
        100% { opacity: 1; transform: scale(1) }
      }
      @keyframes leftImgAnim1 {
        0%   { opacity: 0; filter: none;transform: translateX(0);}
        25%  { opacity: 0; filter: none;transform: translateX(0);}
        26%  { opacity: 0; filter: none;transform: translateX(0);}
        56%  { opacity: 1; filter: none;transform: translateX(0)}
        100% { opacity: 1; filter: none;transform: translateX(0) }
      }
      @keyframes leftImgAnimInfinite {
        0%   { transform: translateY(0);     filter: drop-shadow(5px 5px 3px rgba(84,110,253,0)) }
        35%  { transform: translateY(-10px); filter: drop-shadow(5px 5px 3px rgba(84,110,253,0.8)) }
        40%  { transform: translateY(-10px); filter: drop-shadow(5px 5px 3px rgba(84,110,253,0.8)) }
        45%  { transform: translateY(-10px); filter: drop-shadow(5px 5px 3px rgba(84,110,253,0.8)) }
        100% { transform: translateY(0);     filter: drop-shadow(5px 5px 3px rgba(84,110,253,0)) }
      }
      @keyframes leftImgAnim2 {
        0%   { opacity: 1; filter: none;transform: translateX(28px) translateY(0)}
        20%  { opacity: 1; filter: none;transform: translateX(28px) translateY(0)}
        23%  { opacity: 1; filter: url(#fractal);transform: translateX(28px) translateY(0)}
        25%  { opacity: 1; filter: url(#fractal);transform: translateX(28px) translateY(0)}
        50%  { opacity: 0.5; filter: none;transform: translateX(43px) translateY(15px)}
        60%  { opacity: 0.5; filter: none;transform: translateX(43px) translateY(15px)}
        62%  { opacity: 0.1; filter: none;transform: translateX(28px) translateY(0)}
        66%  { opacity: 0; filter: none;transform: translateX(28px) translateY(0)}
        100% { opacity: 0; filter: none;transform: translateX(28px) translateY(0)}
      }
      @keyframes logoAnim {
        0%    { opacity: 0; transform: scale(1) translateY(30px);   filter: brightness(360%) drop-shadow(0 0 0 white) }
        2%    { opacity: 1; transform: scale(1) translateY(0);   filter: brightness(360%) drop-shadow(0 0 0 white) }
        23%   { opacity: 1; transform: scale(1);   filter: brightness(360%) drop-shadow(0 0 0 white) }
        25%   { opacity: 1; transform: scale(1.2); filter: brightness(360%) drop-shadow(15px 15px 1px white)}
        50%   { opacity: 1; transform: scale(1.2); filter: brightness(360%) drop-shadow(20px 20px 5px white)}
        60%   { opacity: 1; transform: scale(1.2); filter: brightness(360%) drop-shadow(20px 20px 5px white)}
        62%   { opacity: 1; transform: scale(1);   filter: brightness(100%) drop-shadow(1px 1px 1px white)}
        100%  { opacity: 1; transform: scale(1);   filter: brightness(100%) drop-shadow(1px 1px 1px white)}
      }
      img.logo {
        position: absolute;
        top: 12vh;
        left: 13vw;
        width: 300px;
        //filter: drop-shadow(10px 10px 2px white);
        filter: brightness(360%);
        transform: translateY(30px);
        //filter: drop-shadow(2px 2px 10px white);
        opacity: 0;
        animation: @loginLeftAnimationDuration logoAnim forwards linear;
        animation-delay: @loginAnimationDelay;
        z-index: 2;
      }
      .left-bg-outer {
        position: relative;
        width: 100%;
        height: 100%;
        //margin-left: -5vw;
        //padding-top: 5vh;
        .display-flex();
        transform: scale(0);
        animation: 0.5s leftImgShowAnim forwards;
        animation-delay: @loginAnimationDelay;
        img {
          width: 1000px;
          user-select: none;
          left: 0;
        }
        @media screen and (max-width: 1400px) { img { width: 800px;} }
        @media screen and (max-width: 1200px) { img { width: 600px;} }
        img.bg {
          position: absolute;
          //opacity: 0;
          transform: translateX(28px) translateY(0);
          //filter: drop-shadow(10px 10px 1px white);
          //animation: 16s leftImgAnim2 infinite linear;
          animation: @loginLeftAnimationDuration leftImgAnim2 forwards linear;
          animation-delay: @loginAnimationDelay;
        }
        // 有白色竖线
        img.mask {
          opacity: 0;
          position: absolute;
          //filter: drop-shadow(10px 10px 1px white);
          //animation: 16s leftImgAnim1 infinite linear;
          animation: @loginLeftAnimationDuration leftImgAnim1 forwards linear, 5s leftImgAnimInfinite infinite linear;
          animation-delay: @loginAnimationDelay, @loginAnimationDelay + @loginLeftAnimationDuration;
        }
      }
      svg {
        position: absolute;
        width: 0;
        height: 0;
        left: -9999px;
        pointer-events: none;
      }
    }
    .form-outer {
      position: relative;
      opacity: 0;
      flex: 1;
      padding-bottom: 10vh;
      animation: 0.5s loginFormShow forwards;
      animation-delay: @loginAnimationDelay;
      @keyframes loginFormShow {
        0% { opacity: 0; transform: translateX(-50%) }
        50% { opacity: 0.7; transform: translateX(15px) }
        100% { opacity: 1; transform: translateX(0) }
      }
      .login-right-in-enter,.login-right-in-leave-to {
        opacity: 0;
        transform: rotateY(180deg);
      }
      .login-left-in-enter,.login-left-in-leave-to {
        opacity: 0;
        transform: rotateY(180deg);
      }
      .form-current-outer {
        position: absolute;
        left: 10vw;
        top: 0;
        bottom: 10vh;
        display: flex;
        align-items: center;
        transition: all @transitionTime25;
      }
    }
  }
  footer.login-footer {
    position: absolute;
    left: 0;
    right: 0;
    line-height: 40px;
    bottom: 0;
    text-align: center;
    color: white;
    font-size: 12px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
}
</style>

<template>
  <div id="login">
    <section
      v-if="loginShow"
      class="login-container"
    >
      <!--左侧图片-->
      <div class="login-left-bg">
        <img
          src="/loginImg/logo.png"
          class="logo"
        >
        <div class="left-bg-outer">
          <img
            src="/loginImg/login_left5.png"
            class="bg"
          >
          <img
            src="/loginImg/login_left3.png"
            class="mask"
          >
        </div>
        <svg class="distort">
          <filter
            id="fractal"
            filterUnits="objectBoundingBox"
            x="0%"
            y="0%"
            width="500px"
            height="500px"
          >
            <feTurbulence
              id="turbulence"
              type="fractalNoise"
              baseFrequency="0.08"
              numOctaves="1"
            >
              <animate
                attributeName="baseFrequency"
                from="0.1 0.1"
                to="0.07 0.01"
                dur="3.5s"
                repeatCount="indefinite"
              />
            </feTurbulence>
            <feDisplacementMap
              in="SourceGraphic"
              scale="15"
            />
          </filter>
        </svg>
      </div>
      <!--右侧表单-->
      <div class="form-outer">
        <transition
          name="login-right-in"
        >
          <div
            v-show="formIsLoginType"
            class="form-current-outer"
          >
            <v-login-form
              ref="login"
              @on-forget="onForget"
            />
          </div>
        </transition>
        <transition
          name="login-left-in"
        >
          <div
            v-show="!formIsLoginType"
            class="form-current-outer form-current-outer-2"
          >
            <v-forget
              @on-finish="onFinish"
            />
          </div>
        </transition>
      </div>
    </section>
    <!--页面底部-->
    <footer class="login-footer">
      <span>Copyright © 2023-{{ currentYear }} 吾行畅充版权所有</span>
    </footer>
  </div>
</template>

<script>
import vLoginForm from './view/login.vue';
import vForget from './view/forget.vue';

const FORM_TYPE_LOGIN = 'login';
const FORM_TYPE_FORGET = 'forget';
export default {
  components: { vLoginForm, vForget },
  data () {
    return {
      /**
       * 展示的表单内容
       * 1：登录
       * 2：忘记密码
       */
      formType: '',
      currentYear: '',
      loginShow: false
    };
  },
  computed: {
    formIsLoginType () {
      return this.formType === FORM_TYPE_LOGIN;
    }
  },
  beforeCreate () {
    const { query } = this.$route;
    if (!query.reloadFinish) {
      window.location.href = window.location.href + (window.location.href.includes('?') ? '&reloadFinish=1' : '?reloadFinish=1');
    }
  },
  created () {
    console.log('login-created ===================>');
    const { formType } = this.$route.query;
    this.loginShow = !!this.$route.query.reloadFinish;
    this.formType = formType === FORM_TYPE_FORGET ? FORM_TYPE_FORGET : FORM_TYPE_LOGIN;
    this.$storage.token = null;
    this.$storage.user = null;
    this.currentYear = (new Date()).getFullYear();
    this.$nextTick(() => {
      document.getElementById('app').style = 'width: 100vw;min-width: 100vw';
    });
  },
  destroyed () {
    document.getElementById('app').style = '';
  },
  mounted () {},
  methods: {
    onForget () {
      this.formType = FORM_TYPE_FORGET;
    },
    onFinish (e) {
      this.formType = FORM_TYPE_LOGIN;
      if (e) {
        this.$refs.login.$quickLogin(e);
      }
    }
  }
};
</script>
